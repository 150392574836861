import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar,Space } from "antd";
import useFetchUser from '../../hooks/profile/useProfile'; // Adjust this path accordingly
import { connect } from 'react-redux';
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/"
  },
  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/"
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  }
];

export const NavProfile = ({ signOut }) => {
  const { data: userData, isLoading, error } = useFetchUser();
  const [profileData, setProfileData] = useState({
    name: "Loading...",
    userType: "Loading...",
    profileImg: "/img/avatars/default.png"
  });

  useEffect(() => {
    if (userData && userData.userProfile) {
      setProfileData({
        ...profileData,
        name: userData.userProfile.name,
        userType: userData.userProfile.userType,
      });
    }
  }, [userData]);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileData.profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{profileData.name}</h4>
            <span className="text-muted">{profileData.userType}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
        <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <Space>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
              </Space>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileData.profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile);
