import { useGraphQLQuery } from '../../queries/main/main'; // Adjust this import to the actual file path
import { gql } from 'graphql-request';
import { message } from 'antd';
// Define your GraphQL query
const USER_PROFILE_QUERY = gql`
query GetUserProfile($userId: ID!) {
  userProfile(userId: $userId) {
    userType
    name
    _id
  }
}
`;

const useFetchUser = () => {
  
  const userId = localStorage.getItem("userId");

  return useGraphQLQuery(
    "user",
    USER_PROFILE_QUERY,
    { userId }, // Pass the userId as a variable to the query
    {
      keepPreviousData: true,
      onError: (error) => {
        message.error("An error occurred while fetching user.");
      },
    }
  );
};

export default useFetchUser;
