import { gql } from 'graphql-request';
import graphqlClient from '../../../graphqlClient'; // Adjust the path as needed

export const executeDetachDeviceQuery = async ({ userId, deviceId, currentDeviceId }) => {
  const mutation = gql`
    mutation DetachDevice($userId: ID!, $deviceId: String!, $currentDeviceId: String!) {
      detachDevice(userId: $userId, deviceId: $deviceId, currentDeviceId: $currentDeviceId) {
        ... on AuthData {
          userId
          token
          tokenExpiration
        }
        ... on StatusResponse {
          statusCode
          message
        }
      }
    }
  `;

  const variables = { userId, deviceId, currentDeviceId };

  try {
    console.log('Executing detach device mutation with variables:', variables); // Debug log
    const response = await graphqlClient(mutation, variables);
    console.log('Detach device mutation response:', response); // Debug log
    return response.detachDevice;
  } catch (error) {
    console.error('Error executing detach device mutation:', error);  // Log the error for debugging
    throw error;  // Re-throw to handle in saga or wherever this function is called
  }
};
