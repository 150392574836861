import { gql } from 'graphql-request';
import graphqlClient from '../../graphqlClient';

export const executeLoginQuery = async ({ phonenumber, password, deviceId }) => {
  const query = gql`
    query Login($phonenumber: String!, $password: String!, $deviceId: String!) {
      login(phonenumber: $phonenumber, password: $password, deviceId: $deviceId) {
        ... on AuthData {
          userId
          token
          tokenExpiration
        }
        ... on StatusResponse {
          statusCode
          message
        }
        ... on DeviceManagerResponse {
          statusCode
          deviceManagerMessage: message
          userId
          deviceIds
          availableQuota
          actionRequired
        }
      }
    }
  `;

  return await graphqlClient(query, { phonenumber, password, deviceId });
};
