import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  DETACH_DEVICE,
  DETACH_DEVICE_SUCCESS,
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
  detachDevice,
  deviceDetachRequired,
  detachDeviceSuccess
} from '../actions/Auth';
import { executeLoginQuery } from '../../queries/login/loginQuery';
import { executeDetachDeviceQuery } from '../../queries/login/detach/detachQuery';
export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { phonenumber, password, deviceId } = payload;
    try {
      const response = yield call(executeLoginQuery, { phonenumber, password, deviceId });
      const loginData = response.login;

      if (loginData && loginData.userId && loginData.token) {
        // Successful login
        localStorage.setItem(AUTH_TOKEN, loginData.token);
        localStorage.setItem('userId', loginData.userId);
        yield put(authenticated(loginData.userId));
      } else if (loginData.statusCode === 409) {
        // Device detach required
        yield put(deviceDetachRequired({
          deviceIds: loginData.deviceIds,
          userId: loginData.userId,
          currentDeviceId: deviceId, // Pass the current device ID
          message: loginData.deviceManagerMessage,
        }));
      } else if (loginData.statusCode) {
        // Failure case with a status code
        yield put(showAuthMessage(loginData.message || loginData.deviceManagerMessage));
      } else {
        // Unknown error
        yield put(showAuthMessage('An unknown error occurred during login.'));
      }
    } catch (err) {
      yield put(showAuthMessage('Login failed. Please try again.'));
    }
  });
}

export function* handleDetachDevice() {
  yield takeEvery(DETACH_DEVICE, function* ({ payload }) {
    const { userId, deviceId, currentDeviceId } = payload;
   
    try {
      const response = yield call(executeDetachDeviceQuery, { userId, deviceId, currentDeviceId });
      console.log(response);
      if (response.token) {
        localStorage.setItem(AUTH_TOKEN, response.token);
        localStorage.setItem('userId', response.userId);
        window.location.reload();
      } else {
        // Handle error case
        yield put(showAuthMessage(response.message || 'Failed to detach device.'));
      }
    } catch (err) {
      yield put(showAuthMessage('Failed to detach device. Please try again.'));
    }
  });
}


// export function* signInWithFBEmail() {
//   yield takeEvery(SIGNIN, function* ({ payload }) {
//     const { phonenumber, password, deviceId } = payload;
//     try {
//       // Directly call the GraphQL query function
//       const response = yield call(executeLoginQuery, { phonenumber, password, deviceId });
//       const loginData = response.login;
  
//       if (loginData && loginData.userId && loginData.token) {
//         // Success case
//         localStorage.setItem(AUTH_TOKEN, loginData.token);
//         localStorage.setItem('userId', loginData.userId);
//         yield put(authenticated(loginData.userId));
//       } else if (loginData.statusCode) {
//         // Failure case with a status code
//         yield put(showAuthMessage(loginData.message || loginData.deviceManagerMessage));
//       } else {
//         // Unknown error
//         yield put(showAuthMessage('An unknown error occurred during login.'));
//       }
//     } catch (err) {
//       yield put(showAuthMessage('Login failed. Please try again.'));
//     }
//   });
// }

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('userId');
      window.location.reload();
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

// Additional sagas for other actions...

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(handleDetachDevice),
    // Fork other sagas here as needed
  ]);
}
