import { 
  BookOutlined, 
  ClusterOutlined, 
  CommentOutlined, 
  ContainerOutlined, 
  ScheduleFilled, 
  UserOutlined, 
  WeiboCircleOutlined 
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import jwt from 'jwt-decode';


let role = "";
let phone = "";
let deviceIds = "";
let permissions = "";
const token = localStorage.getItem("auth_token");

if (token) {
  try {
    const decodedToken = jwt(token);
    role = decodedToken.userType;
    phone = decodedToken.phonenumber;
    deviceIds = decodedToken.deviceId;
    permissions = decodedToken.permissions;
  } catch (error) {
    console.error("Invalid token", error);
    logout();
  }
}

// Redirect to login if any of the required fields are missing
 if (!permissions || !phone || !role) {
    logout();
  }

  function logout() {
    localStorage.removeItem("auth_token");
  }

const AdmindashBoardNavTree = [
  {
    title: "Course Management",   
    key: "courses",
    breadcrumb: false,
    submenu: [
      {
        title: "School",
        path: `${APP_PREFIX_PATH}/courses/school`,
        icon: WeiboCircleOutlined,
        key: 'school'
      },
      {
        title: "Grade",
        path: `${APP_PREFIX_PATH}/courses/grade`,
        icon: ScheduleFilled,
        key: 'grade'
      },
      {
        title: "Course",
        path: `${APP_PREFIX_PATH}/courses/course`,
        icon: BookOutlined,
        key: 'course',
      },
      {
        title: "Contributors",
        path: `${APP_PREFIX_PATH}/contributors`,
        icon: ClusterOutlined,
        key: 'contributors',
      },
      {
        title: "All Discussions",
        path: `${APP_PREFIX_PATH}/discussions`,
        icon: CommentOutlined,
        key: 'alldiscussion',
      },
      {
        title: "Feedbacks",
        path: `${APP_PREFIX_PATH}/feedback`,
        icon: ContainerOutlined,
        key: 'feedback',
      }
    ],
  },
  {
    title: "User Management",
    key: "users",
    breadcrumb: false,
    submenu: [
      {
        title: "Users",
        path: `${APP_PREFIX_PATH}/users`,
        icon: UserOutlined,
        key: 'user',
      },
    ],
  },
];

const TeacherdashBoardNavTree = [
  {
    title: "Teacher Module",       
    key: "tdiscussion",
    breadcrumb: false,
    submenu: [
      {
        title: "Discussions",
        path: `${APP_PREFIX_PATH}/discussion/teacher`,
        icon: CommentOutlined,
        key: 'tdiscussion'
      },
      {
        title: "Assignments",
        path: `${APP_PREFIX_PATH}/assignments/teacher`,
        icon: BookOutlined,
        key: 'assignments'
      },
    ]
  }
];

const StudentdashBoardNavTree = [
  {
    title: "Discussion Management",  
    key: "sdiscussion",
    breadcrumb: false,
    submenu: [
      {
        title: "Student Discussion",
        path: `${APP_PREFIX_PATH}/discussion/student`,
        icon: CommentOutlined,
        key: 'sdiscussion'
      },
    ]
  }
];

let navigationConfig = [];

switch (role) {
  case "admin":
    navigationConfig = [...AdmindashBoardNavTree];
    break;
  case "teacher":
    navigationConfig = [...TeacherdashBoardNavTree];
    break;
  case "student":
  case "parents":
    navigationConfig = [...StudentdashBoardNavTree];
    break;
  default:
    break;
}

export default navigationConfig;
