// src/graphqlClient.js
import { request, gql } from 'graphql-request';
import { env } from 'configs/EnvironmentConfig';

const graphqlClient = async (query, variables = {}) => {


  return request(env.API_ENDPOINT_URL, query, variables);
};

export default graphqlClient;


